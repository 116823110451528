<template>
  <page-layout-nolo :title="$t('recoverPassword')" :loading="loading">
    <v-row>
      <v-col cols="12" class="nolo-body">
        <p>{{ $t('message.passwordTip') }}</p>
        <v-form v-model="validChangeForm">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field outlined type="password" :label="$t('label.newPassword')"
                            required
                            :rules="[inputRules.required, inputRules.validPassword]"
                            v-model="newPassword"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field outlined type="password" :label="$t('label.confirmPassword')"
                            required
                            :rules="[inputRules.required, validConfirmation]"
                            v-model="confirmPassword"/>
            </v-col>
          </v-row>
        </v-form>
        <v-row dense>
          <v-col>
            <v-btn @click="changePassword" :disabled="!validChangeForm" color="primary" :loading="working"
                   class="mb-4">{{ $t('btn.updatePassword') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </page-layout-nolo>
</template>

<script>

import APICustomer from "@/api/APICustomer";

export default {
  name: "RecoverPassword",
  mounted(){
    const me = this;
    // eslint-disable-next-line no-debugger
    debugger;
    APICustomer.validateRecoveryToken(this, this.token)
        .then(()=> {

        })
        .catch( ()=> {
          me.appShowErrorDialog(me.$i18n.t('invalidPasswordToken'), 'error', me.invalidTokenOk)
        })
        .finally(() => {
          me.loading = false;
        });
  },
  methods: {
    changePassword() {
      const me = this;
      me.working = true;
      APICustomer.resetPasswordFromToken(this, this.token, this.newPassword)
          .then(()=> {
            me.appShowErrorDialog(me.$i18n.t('resetPasswordSuccessfully'), 'success', me.passwordResetOk)
          })
          .catch(()=> {
            me.appShowErrorDialog(me.$i18n.t('error.resetPasswordFailed'), 'error')
          })
          .finally(() => {
            me.working = false;
          });

    },
    passwordResetOk(){
      this.$router.replace({name: 'account'})
    },
    invalidTokenOk(){
      this.$router.replace({name: 'forgot-password'})
    },
    validConfirmation(v) {
      return v === this.newPassword ? true : this.$t('error.passwordDoesntMatch');
    },

  },
  data() {
    return {
      validChangeForm: false,
      newPassword: '',
      confirmPassword: '',
      working: false,
      loading: true,
    }
  },

  computed: {

    token() {
      return this.$route.params.token
    },
  }
}
</script>

<style scoped>


</style>